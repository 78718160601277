export const RecordFields = {
    // Car refill fields
    before_refill: 'До заправки',
    volume: 'Объем',
    after_refill: 'После заправки',
    gps_current: 'Оператор GPS',
    odometer_current: 'Одометр',
    started_at: 'Дата заправки машины',
    car: 'Машина',
    station: 'Станция заправки',
    driver: 'Водитель',
    fuel_type: 'Тип топлива',
    ride_type: 'Тип езды',

    // Station refill fields
    terminal: 'Терминал',
    date: 'Дата заправки станции',
    current: 'Состояние',
    name: 'Название',
};