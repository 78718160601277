import * as React from "react";
import axios from "../lib/axios";

export default function IsSignedIn(callback) {
    let token = localStorage.getItem('at');
    if (token !== null) {
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
        axios.options('/main/Station/').then((response) => {
            console.log('Checking Token!');
            callback(true);
        }).catch(function (error) {
            localStorage.removeItem('at');
            if (axios.defaults.headers.common.hasOwnProperty('Authorization'))
                delete axios.defaults.headers.common.Authorization;
            callback(false)
        });
        return;
    }
    callback(false);
}