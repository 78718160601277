import * as React from "react";
import axios from "../lib/axios";
import {DataGrid, GridRenderCellParams, GridValueFormatterParams, ruRU} from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import {createTheme, Theme} from '@mui/material/styles';
import BasicDateRangePicker from "../components/BasicDateRangePicker";
import {CarStatsFilterInterface, DateRange} from "../lib/interfaces";
import {default as dayjs} from 'dayjs';
import {CustomFooterTotalComponent} from "../components/CustomFooterTotalComponent";
import {getTerminalInfoByStationAndFuel, loadFiltersData} from "../lib/helper";
import StationFilter from "../components/filters/StationFilter";
import FuelTypeFilter from "../components/filters/FuelTypeFilter";
import CarAutocomplete from "../components/filters/CarAutocomplete";
import BaseComponent from "../components/BaseComponent";
import {connect} from "react-redux";
import GlobalReduxState from "../data_structures/GlobalReduxState";
import CarState from "../data_structures/CarState";
import {Terminals} from "../data_structures/StationState";

class Records extends React.Component<{
    reduxState: any,
    dispatch: any,
    terminals: Terminals,
    car: CarState
},
    {
        header: Array<any>,
        rows: Array<any>,
        total_vol: number,
        stationCurrent: number | null,
        menuOpen: boolean,
        filters: CarStatsFilterInterface,
    }> {
    mdTheme: Theme;
    data: object;
    dateFormat: string = 'YYYY-MM-DD';

    constructor(props) {
        super(props);
        this.state = {
            filters: {
                date: {from: dayjs(), to: dayjs()},
                station: '__all__',
                car: null,
                fuel_type: '__all__'
            },
            header: [
                {
                    field: 'date',
                    headerName: 'Дата',
                    minWidth: '150',
                    type: 'string',
                    valueFormatter: (params: GridValueFormatterParams<string>) => {
                        const _d = new Date(Date.parse(params.value));
                        return _d.toLocaleString();
                    }
                },
                {
                    field: 'car',
                    headerName: 'Машина',
                    type: 'number',
                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                        if (params.value == null || !this.props.car.cars[params.value]) {
                            return params.value.toLocaleString();
                        }

                        const valueFormatted = this.props.car.cars[params.value].name;
                        return `${valueFormatted}`;
                    },
                },
                {
                    field: 'station',
                    headerName: 'Станция Заправки',
                    minWidth: 150,
                    renderCell: (params: GridRenderCellParams<number>) => {
                        if (!this.props.reduxState.stations.terminals[params.value]) {
                            return <>params.value</>;
                        }
                        const valueFormatted = this.props.reduxState.stations.stations[this.props.reduxState.stations.terminals[params.value].station].name;
                        return <>
                            {valueFormatted}
                        </>;
                    },
                },
                {
                    field: 'before_refill',
                    headerName: 'Начальный',
                    type: 'number',
                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                        if (params.value == null) {
                            return '';
                        }

                        const valueFormatted = Number(params.value).toLocaleString();
                        return `${valueFormatted} Л`;
                    },
                },
                {
                    field: 'volume',
                    headerName: 'Топливо',
                    type: 'number',
                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                        if (params.value == null) {
                            return '';
                        }

                        const valueFormatted = Number(params.value).toLocaleString();
                        return `${valueFormatted} Л`;
                    },
                },
                {
                    field: 'after_refill',
                    headerName: 'Конечный',
                    type: 'number',
                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                        if (params.value == null) {
                            return '';
                        }

                        const valueFormatted = Number(params.value).toLocaleString();
                        return `${valueFormatted} Л`;
                    },
                },
                {
                    field: 'gps_current',
                    headerName: 'Оператор GPS',
                    minWidth: '150',
                    type: 'number',

                    renderCell: (params: GridRenderCellParams<Number>) => {
                        let diff = Number(params.row.volume) - Number(params.value);
                        let addition = <></>;
                        if (diff > 0) {
                            addition = (
                                <strong style={{color: "#ff0000"}}>&nbsp;({diff})</strong>
                            );
                        } else if (diff < 0) {
                            addition = (
                                <strong style={{color: "#00ff00"}}>&nbsp;({Math.abs(diff)})</strong>
                            );
                        }
                        return <>
                            {params.value} {addition} &nbsp;Л
                        </>;
                    },
                },
                {
                    field: 'odometer_current',
                    headerName: 'Одометр(Км)',
                    type: 'number',
                    minWidth: 150,
                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                        if (params.value == null) {
                            return '';
                        }

                        const valueFormatted = Number(params.value).toLocaleString();
                        return `${valueFormatted} км`;
                    },
                },
            ],
            menuOpen: true,
            rows: [],
            stationCurrent: null,
            total_vol: 0
        };
        this.mdTheme = createTheme({}, ruRU);
    }

    componentDidMount() {
        this.props.dispatch(loadFiltersData);
        this.setData();
    }

    handleFilters(_map: object) {
        this.setState({
            ...this.state,
            rows: [],
            filters: {
                ...this.state.filters,
                ..._map
            }
        }, () => this.setData());
    }

    setData() {
        let params = {};
        if (this.state.filters.date.from && this.state.filters.date.to) {
            params['timeframe_after'] = this.state.filters.date.from.format(this.dateFormat);
            params['timeframe_before'] = this.state.filters.date.to.format(this.dateFormat);
        }
        if (this.state.filters.station > 0) {
            params['terminal'] = this.state.filters.station;
        }
        if (this.state.filters.fuel_type > 0) {
            params['fuel_type'] = this.state.filters.fuel_type;
        }
        if (this.state.filters.car !== null) {
            params['car'] = this.state.filters.car.id;
        }
        axios.get('/refills/CarRefills/',
            {
                params: params
            }
        ).then((response) => {
            let total_vol = 0;
            for (let [key, value] of Object.entries(response.data['results'])) {
                for (const [k, val] of Object.entries(value)) {
                    if (val == null) {
                        value[k] = 0;
                    }
                }
                total_vol += Number(value['volume']);
            }
            let _state = {
                'rows': response.data.results,
                'total_vol': total_vol,
            };
            if (this.state.filters.station != '__all__' && this.state.filters.fuel_type != '__all__') {
                const terminal = getTerminalInfoByStationAndFuel(Object.values(this.props.terminals), this.state.filters.station, this.state.filters.fuel_type);
                _state['stationCurrent'] = Number(terminal ? terminal.current : 0);
            } else {
                _state['stationCurrent'] = null;
            }
            this.setState({
                ...this.state,
                ..._state
            });
        });
    }

    render() {
        return (
            <BaseComponent>
                <Grid container spacing={3}>
                    <Grid item xs={12}><BasicDateRangePicker range={this.state.filters.date}
                                                             setter={(_range: DateRange) => this.handleFilters({date: _range})}/></Grid>
                    <Grid item xs={12} md={4}>
                        <StationFilter
                            asFilter={true}
                            value={this.state.filters.station}
                            onChange={(e) => this.handleFilters({station: e.target.value})}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FuelTypeFilter
                            asFilter={true}
                            onChange={(e) => this.handleFilters({fuel_type: e.target.value})}
                            value={this.state.filters.fuel_type}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CarAutocomplete
                            value={this.state.filters.car}
                            asFilter={true}
                            onChange={(value) => this.handleFilters({car: value})}/>
                    </Grid>
                    <Grid item xs={12} md={8} lg={12}>
                        <div style={{height: 500, width: '100%'}}>
                            <h2>Топливо</h2>
                            <DataGrid columns={this.state.header} rows={this.state.rows}
                                      pageSize={10}
                                      rowsPerPageOptions={[10]}
                                      components={{
                                          Footer: CustomFooterTotalComponent,
                                      }}
                                      componentsProps={{
                                          footer: {
                                              total: this.state.total_vol,
                                              stationCurrent: this.state.stationCurrent
                                          },
                                      }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </BaseComponent>
        );
    }
}

const setStateToProps = (state: GlobalReduxState) => ({
    'reduxState': state,
    'terminals': state.stations.terminals,
    'car': state.car
});

export default connect(setStateToProps)(Records);