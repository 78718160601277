import React from "react";
import {connect} from "react-redux";
import GlobalReduxState from "../data_structures/GlobalReduxState";
import LoginForm from "../components/Accounts/LoginForm";

const AuthProvider = ({children, token, dispatch}) => {
    if (!token) {
        return <LoginForm/>
    }
    return <>{children}</>;
}

const setStateToProps = (state: GlobalReduxState) => ({'token': state.auth.token});

export default connect(setStateToProps)(AuthProvider);