import {setStations, setTerminals} from "../store/station";
import {getStationsList} from "./StationInfo";
import {getDriverList} from "./DriverInfo";
import {setCars, setDrivers} from "../store/car";
import {getCarList} from "./CarInfo";

export function getAPIUrl() {
    return process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_ROOT;
}

export function getTerminalInfoByStationAndFuel(terminals: Array<any>, station_id: string | number, fuel_type: string | number) {
    for (const terminal of terminals) {
        if (terminal.station == station_id && fuel_type == terminal.fuel_type) {
            return terminal;
        }
    }
    return null;
}


export function loadFiltersData(dispatch, getState) {
    if (Object.keys(getState().stations.stations).length == 0) {
        getStationsList().then((results) => {
            let stations = {};
            let terminals = {}
            for (let station of results) {
                stations[station.id] = station;
                for (let terminal of station.fuel_terminals) {
                    terminals[terminal.id] = terminal;
                    terminals[terminal.id].station = station.id;
                }
            }
            dispatch(setStations(stations));
            dispatch(setTerminals(terminals));
        });
    }
    if (Object.keys(getState().car.cars).length == 0) {
        getCarList().then((cars) => {
            let _cars = {};
            for (const car of cars) {
                _cars[car.id] = car;
            }
            dispatch(setCars(_cars));
        });
        getDriverList().then((results) => {
            let drivers = {};
            for (const driver of results) {
                drivers[driver.id] = driver;
            }
            dispatch(setDrivers(drivers));
        })
    }
}
