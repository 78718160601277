import * as React from "react";
import {FormControl, MenuItem, TextField} from "@mui/material";
import Station from "../../data_structures/Station";
import {connect} from "react-redux";

class StationFilter extends React.Component<{
    asFilter: boolean,
    required?: boolean,
    value: any,
    helperText?: string,
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    stations: Array<Station>
}, {}> {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    getStations() {
        let list = [];
        if (this.props.asFilter)
            list.push(<MenuItem value={'__all__'} key="station_0">Все Станции</MenuItem>);
        this.props.stations.map((station) => {
            list.push(<MenuItem value={station.id}
                                key={`station_${station.id}`}>{station.name}</MenuItem>);
        });
        return list;
    }

    render() {
        return <>
            <FormControl fullWidth>
                <TextField
                    required={this.props?.required}
                    select
                    id="station-select"
                    label="Станция Заправки"
                    value={this.props.value ? this.props.value : ''}
                    onChange={this.props.onChange}
                    helperText={this.props.helperText}
                >
                    {this.getStations()}
                </TextField>
            </FormControl>
        </>;
    }
}

const setStoreToProps = (state) => ({'stations': Object.values(state.stations.stations)});

export default connect(setStoreToProps)(StationFilter);