import {createSlice} from "@reduxjs/toolkit";
import CarState from "../data_structures/CarState";

const initialState: CarState = <CarState>{cars: {}, drivers: {}};

const carSlice = createSlice({
    name: 'car',
    initialState,
    reducers: {
        setDrivers: (state, action) => {
            state.drivers = action.payload;
        },
        setCars: (state, action) => {
            state.cars = action.payload;
        },
    }
})

export const drivers = (state) => state.car.drivers;

export const {setDrivers, setCars} = carSlice.actions;
export default carSlice.reducer;