import * as React from 'react';
import LoginForm from "../components/Accounts/LoginForm";
import {Navigate} from "react-router-dom";
import IsSignedIn from "../lib/accounts";

export default class Login extends React.Component<{}, {
    navigate: boolean
}> {

    constructor(props) {
        super(props);
        this.state = {navigate: false};
    }

    componentDidMount() {
        if (!this.state.navigate)
            IsSignedIn((signed) => {
                if (signed) {
                    this.setState({...this.state, navigate: true});
                }
            });
    }

    render() {
        if (this.state.navigate) {
            return <>
                <Navigate to={'/'}/>
            </>;
        }
        return <>
            <LoginForm/>
        </>;
    }
}