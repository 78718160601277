import {configureStore} from "@reduxjs/toolkit";

import stationReducer from "store/station";
import themeReducer from "store/theme";
import carReducer from "store/car";
import authReducer from "store/auth";

export const store = configureStore({
    reducer: {
        stations: stationReducer,
        theme: themeReducer,
        car: carReducer,
        auth: authReducer
    },
})

