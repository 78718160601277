import React from "react";
import {createTheme, Theme, ThemeProvider} from "@mui/material/styles";
import {ruRU} from "@mui/x-data-grid";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Drawer, {AppBar} from "./Drawer";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {mainListItems, secondaryListItems} from "../Pages/listItems";
import Container from "@mui/material/Container";
import NotificationsIcon from '@mui/icons-material/Notifications';
import {connect} from 'react-redux';
import {toggleMenu} from "../store/theme";
import GlobalReduxState from "../data_structures/GlobalReduxState";
import ThemeState from "../data_structures/ThemeState";
import {Backdrop} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ProfileMenu from "./Accounts/ProfileMenu";
import LogoutDialog from "./Accounts/LogoutDialog";

class BaseComponent extends React.Component<{
    children: any,
    theme: ThemeState,
    dispatch: any
}, {}> {
    mdTheme: Theme

    constructor(props) {
        super(props);
        this.state = {}
        this.mdTheme = createTheme({}, ruRU);
    }

    componentDidMount() {
    }

    toggleDrawer(e) {
        this.props.dispatch(toggleMenu(null))
    }

    render() {
        return <ThemeProvider theme={this.mdTheme}>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={this.props.theme.pageLoader}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <LogoutDialog></LogoutDialog>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute" open={this.props.theme.menu}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={(e) => this.toggleDrawer(e)}
                            sx={{
                                marginRight: '36px',
                                ...(this.props.theme.menu && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{flexGrow: 1}}
                        >
                            Главная
                        </Typography>
                        <IconButton color="inherit" onClick={() => {
                        }}>
                            <NotificationsIcon/>
                        </IconButton>
                        <ProfileMenu/>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={this.props.theme.menu}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={(e) => this.toggleDrawer(e)}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        {mainListItems}
                        <Divider sx={{my: 1}}/>
                        {secondaryListItems}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                        {this.props.children}
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>;
    }
}

const mapStateToProps = ((state: GlobalReduxState) => ({theme: state.theme}));

export default connect(mapStateToProps)(BaseComponent);