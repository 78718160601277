import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import * as React from "react";
import {GridFooter, GridFooterContainer} from "@mui/x-data-grid";

export function CustomFooterTotalComponent(props: {
    total: number,
    stationCurrent: number | null
}) {
    return <GridFooterContainer><Box style={{clear: "both"}}><Box sx={{p: 1, display: 'flex'}}>Итого: {props.total} Л </Box>{props.stationCurrent == null ? '' : (
        <Box sx={{p: 1, display: 'flex'}}><Divider variant="fullWidth" orientation="vertical"/>Остаток
            станции: {props.stationCurrent}</Box>)}</Box> <GridFooter sx={{
        border: 'none', // To delete double border.
    }} /></GridFooterContainer>;
}