import {CarFields} from "./interfaces";
import axios from "../lib/axios";

export default async function CarInfo(id: number): Promise<CarFields> {
    let result = await axios.get(`/main/Car/${id}/`);
    return {
        id: result.data.id,
        number: result.data.number,
        own: result.data.own,
        name: result.data.display_name,
        odometer_current: result.data.odometer_current
    };
}

export async function getCarList(): Promise<Array<CarFields>> {
    let result = await axios.get('/main/Car/', {
        params: {
            page_size: 1000
        }
    });
    let data = [];
    for (const k in result.data.results) {
        data.push({
            id: result.data.results[k].id,
            number: result.data.results[k].number,
            own: result.data.results[k].own,
            name: result.data.results[k].display_name,
            odometer_current: result.data.results[k].odometer_current
        });
    }
    return data;
}