import * as React from "react";
import Grid from '@mui/material/Grid';
import StationStats from "../components/StationStats";
import FuelStats from "../components/FuelStats";
import BaseComponent from "../components/BaseComponent";

export default class Home extends React.Component<{}, {}> {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <BaseComponent>
            <Grid container spacing={3}>
                <StationStats/>
            </Grid>
            <Grid container spacing={3} sx={{mb: 4}}>
                <FuelStats/>
            </Grid>
        </BaseComponent>
    }
}