import * as ReactDOM from "react-dom/client";
import Home from "./Pages/Home";
import * as React from "react";
import Dashboard from "./Pages/Dashboard";
import Record from "./Pages/Record";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Records from "./Pages/Records";
import Login from "./Pages/Login";
import {Provider} from 'react-redux';
import {store} from "./store";
import AuthProvider from "./context/AuthProvider";
import {setAxiosDefaults} from "./lib/axios";

let authToken = localStorage.getItem('at');
if (authToken !== null && authToken.length > 0) {
    setAxiosDefaults({authToken});
}

ReactDOM.createRoot(document.querySelector("#app")).render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={<Navigate to="/Home"/>}/>
                        <Route index path="/Home" element={<Home/>}/>
                        <Route path="/Dashboard" element={<Dashboard/>}/>
                        <Route path="/Record" element={<Record/>}/>
                        <Route path="/Records" element={<Records/>}/>
                        <Route path="/Login" element={<Login/>}/>
                        <Route path="*" element={<Navigate to="/Home"/>}/>
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
)
;
