import * as React from "react";
import BasicDateRangePicker from "./BasicDateRangePicker";
import {DateRange, StationStatsFilterInterface} from "../lib/interfaces";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import axios from "../lib/axios";
import {DataGrid, GridRenderCellParams, GridValueFormatterParams} from "@mui/x-data-grid";
import {CustomFooterTotalComponent} from "./CustomFooterTotalComponent";
import StationFilter from "./filters/StationFilter";
import {getTerminalInfoByStationAndFuel, loadFiltersData} from "../lib/helper";
import FuelTypeFilter from "./filters/FuelTypeFilter";
import {connect} from "react-redux";
import GlobalReduxState from "../data_structures/GlobalReduxState";
import StationState from "../data_structures/StationState";

class StationStats extends React.Component<{
    reduxState: GlobalReduxState,
    stations: StationState,
    dispatch: any
}, {
    header: Array<any>,
    rows: Array<any>,
    total_vol: number,
    stationCurrent: number | null,
    filters: StationStatsFilterInterface
}> {
    data: object;
    dateFormat: string = 'YYYY-MM-DD';

    constructor(props) {
        super(props);
        this.state = {
            filters: {
                date: {from: dayjs(), to: dayjs()},
                station: '__all__',
                fuel_type: '__all__'
            },
            rows: [],
            total_vol: 0,
            header: [
                {
                    field: 'date',
                    headerName: 'Дата'
                },
                {
                    field: 'terminal',
                    headerName: 'Станция Заправки',
                    minWidth: '150',
                    renderCell: (params: GridRenderCellParams<Number>) => {
                        if (!this.props.stations.terminals || !this.props.stations.terminals.hasOwnProperty(params.value.toString())) {
                            return <>
                                {params.value.toString()}
                            </>;
                        }
                        const station_id = this.props.stations.terminals[params.value.toString()].station;
                        const valueFormatted = this.props.stations.stations[station_id].name;
                        return <>
                            {valueFormatted}
                        </>;
                    },
                },
                {
                    field: 'volume',
                    headerName: 'Объём',
                    type: 'number',
                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                        if (params.value == null) {
                            return '';
                        }

                        const valueFormatted = Number(params.value).toLocaleString();
                        return `${valueFormatted} Л`;
                    },
                },
                {
                    field: 'remainder',
                    headerName: 'Остаток до заправки',
                    type: 'number',
                    minWidth: '200',
                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                        if (params.value == null) {
                            return '';
                        }

                        const valueFormatted = Number(params.value).toLocaleString();
                        return `${valueFormatted} Л`;
                    },
                },
                {
                    field: 'after_refill',
                    headerName: 'Остаток после заправки',
                    minWidth: '150',
                    type: 'number',

                    renderCell: (params: GridRenderCellParams<Number>) => {
                        let after_refill = Number(params.row.volume) + Number(params.row.remainder);

                        return <>
                            {after_refill} &nbsp;Л
                        </>;
                    },
                },
            ],
            stationCurrent: null,
        };
    }

    handleFilters(_map: object) {
        this.setState({
            ...this.state,
            rows: [],
            filters: {
                ...this.state.filters,
                ..._map
            }
        }, () => this.setData());
    }

    componentDidMount() {
        this.props.dispatch(loadFiltersData);
        this.setData();
    }

    setData() {
        let params = {};
        if (this.state.filters.date.from && this.state.filters.date.to) {
            params['timeframe_after'] = this.state.filters.date.from.format(this.dateFormat);
            params['timeframe_before'] = this.state.filters.date.to.format(this.dateFormat);
        }
        if (this.state.filters.station != '__all__') {
            params['station'] = this.state.filters.station;
        }
        if (this.state.filters.fuel_type != '__all__') {
            params['fuel_type'] = this.state.filters.fuel_type;
        }
        axios.get('/refills/StationRefills/',
            {
                params: params
            }
        ).then((response) => {
            let rows = [];
            let i = 0;
            let total_vol = 0;
            for (let [key, value] of Object.entries(response.data['results'])) {
                for (const [k, val] of Object.entries(value)) {
                    if (val == null) {
                        value[k] = 0;
                    }
                }
                value['id'] = i;
                total_vol += Number(value['volume']);
                rows.push(value)
                i++;
            }
            let state = {
                'rows': rows,
                'total_vol': total_vol,
            };
            if (this.state.filters.station != '__all__' && this.state.filters.fuel_type != '__all__') {
                const terminal = getTerminalInfoByStationAndFuel(Object.values(this.props.stations.terminals), this.state.filters.station, this.state.filters.fuel_type);

                state['stationCurrent'] = Number(terminal ? terminal.current : 0);
            } else {
                state['stationCurrent'] = null;
            }
            this.setState({
                ...this.state,
                ...state
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <h2>История заправок станций</h2>
                </Grid>
                <Grid item xs={8}>
                    <BasicDateRangePicker range={this.state.filters.date}
                                          setter={(_range: DateRange) => this.handleFilters({date: _range})}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <StationFilter
                        asFilter={true}
                        value={this.state.filters.station}
                        onChange={(e) => (this.handleFilters({station: e.target.value}))}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FuelTypeFilter
                        asFilter={true}
                        value={this.state.filters.fuel_type}
                        onChange={(e) => (this.handleFilters({fuel_type: e.target.value}))}
                    />
                </Grid>
                <Grid item xs={12} md={8} lg={12}>
                    <div style={{height: 400, width: '100%'}}>
                        <div style={{display: 'flex', height: '100%'}}>
                            <div style={{flexGrow: 1}}>
                                <DataGrid columns={this.state.header} rows={this.state.rows}
                                          pageSize={5}
                                          rowsPerPageOptions={[5]}
                                          pagination
                                          components={{
                                              Footer: CustomFooterTotalComponent,
                                          }}
                                          componentsProps={{
                                              footer: {
                                                  total: this.state.total_vol,
                                                  stationCurrent: this.state.stationCurrent
                                              },
                                          }}/>
                            </div>
                        </div>
                    </div>
                </Grid>
            </React.Fragment>
        );
    }
}

const setStoreToProps = (state: GlobalReduxState) => ({'reduxState': state, 'stations': state.stations});

export default connect(setStoreToProps)(StationStats);