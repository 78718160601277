import * as React from 'react';
import dayjs, {Dayjs} from 'dayjs';
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {DateRange} from "../lib/interfaces";
import "../scss/main.scss";


interface DateRangeProps {
    range: DateRange,
    setter: (a: DateRange) => void
}

export default function BasicDateRangePicker(props: DateRangeProps) {
    const [from, setFrom] = React.useState<Dayjs | null>(dayjs());
    const [to, setTo] = React.useState<Dayjs | null>(dayjs());

    return (
        <div className={"dateRangePickerBox"}>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{ start: 'Check-in', end: 'Check-out' }}

            >
                <MobileDatePicker
                    value={from}
                    onChange={(newValue) => {
                        props.setter({...props.range, from: newValue});
                        setFrom(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                <div style={{marginRight:"16px",marginLeft:"16px"}}>до</div>
                <MobileDatePicker
                    value={to}
                    onChange={(newValue) => {
                        props.setter({...props.range, to: newValue});
                        setTo(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </div>
    );
}