import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

export default function ErrorDialog(
    {
        msg,
        handleClose = () => {
        }
    }
) {
    return <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-small"
    >
        <DialogTitle id="alert-dialog-title">
            {"Ошибка на сервере!"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <iframe
                    title="Ошибка на сервере!"
                    width="500px"
                    height="500px"
                    srcDoc={msg}
                ></iframe>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <small id="alert-dialog-small">Пожалуйста, скопируйте текст из диалога и отправьте администратору!</small>
            <Button onClick={handleClose}>Закрыть</Button>
        </DialogActions>
    </Dialog>
}