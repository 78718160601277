import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {authToken, closeLogoutDialog, logout, logoutDialogOpenState} from "../../store/auth";
import axios from "../../lib/axios";

const LogoutDialog = (props) => {
    const logoutDialog = useSelector(logoutDialogOpenState);
    const token = useSelector(authToken);
    const dispatch = useDispatch();
    const handleSubmission = (e) => {
        axios.delete(`/account/Token/${token}/`).then((e) => {
        }).catch(() => {
        }).finally(() => {
            dispatch(logout());
        });
        dispatch(closeLogoutDialog(false));
    };
    return (
        <div>
            <Dialog open={logoutDialog}>
                <DialogTitle>Выход из системы</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span>Вы точно хотите выйти из системы?</span>
                    </DialogContentText>
                    <DialogContentText>
                        <small>Чтобы выйти из системы нажмите на кнопку "Выход"!</small>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmission}>Выход</Button>
                    <Button onClick={() => dispatch(closeLogoutDialog(false))}>Отмена</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default LogoutDialog;