import {createSlice} from "@reduxjs/toolkit";
import AuthState from "../data_structures/AuthState";
import GlobalReduxState from "../data_structures/GlobalReduxState";

const initialState: AuthState = {
    token: localStorage.getItem('at'),
    logoutDialogState: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
            localStorage.setItem('at', action.payload);
        },
        openLogoutDialog: (state, action) => {
            state.logoutDialogState = true;
        },
        closeLogoutDialog: (state, action) => {
            state.logoutDialogState = false;
        },
        logout: state => {
            state.token = '';
            localStorage.setItem('at', '');
        }
    }
})

export const logoutDialogOpenState = (state: GlobalReduxState) => state.auth.logoutDialogState;

export const authToken = (state: GlobalReduxState) => state.auth.token;

export const {setToken, openLogoutDialog, closeLogoutDialog, logout} = authSlice.actions;
export default authSlice.reducer;