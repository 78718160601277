import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CreateIcon from '@mui/icons-material/Create';
import ListIcon from '@mui/icons-material/List';
import {Link} from "react-router-dom";

export const mainListItems = (
    <React.Fragment>
        <Link to={"/Home"}>
            <ListItemButton>
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary="Главная"/>
            </ListItemButton>
        </Link>
        <Link to={"/Record"}>
            <ListItemButton>
                <ListItemIcon>
                    <CreateIcon/>
                </ListItemIcon>
                <ListItemText primary="Добавить"/>
            </ListItemButton>
        </Link>
        <Link to={"/Records"}>
            <ListItemButton>
                <ListItemIcon>
                    <ListIcon/>
                </ListItemIcon>
                <ListItemText primary="Список записей"/>
            </ListItemButton>
        </Link>
        {/*<ListItemButton>*/}
        {/*    <ListItemIcon>*/}
        {/*        <PeopleIcon />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Customers" />*/}
        {/*</ListItemButton>*/}
        {/*<ListItemButton>*/}
        {/*    <ListItemIcon>*/}
        {/*        <BarChartIcon />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Reports" />*/}
        {/*</ListItemButton>*/}
        {/*<ListItemButton>*/}
        {/*    <ListItemIcon>*/}
        {/*        <LayersIcon />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Integrations" />*/}
        {/*</ListItemButton>*/}
    </React.Fragment>
);

export const secondaryListItems = (
    <React.Fragment>
        {/*<ListSubheader component="div" inset>*/}
        {/*    Saved reports*/}
        {/*</ListSubheader>*/}
        {/*<ListItemButton>*/}
        {/*    <ListItemIcon>*/}
        {/*        <AssignmentIcon />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Current month" />*/}
        {/*</ListItemButton>*/}
        {/*<ListItemButton>*/}
        {/*    <ListItemIcon>*/}
        {/*        <AssignmentIcon />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Last quarter" />*/}
        {/*</ListItemButton>*/}
        {/*<ListItemButton>*/}
        {/*    <ListItemIcon>*/}
        {/*        <AssignmentIcon />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Year-end sale" />*/}
        {/*</ListItemButton>*/}
    </React.Fragment>
);