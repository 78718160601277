import {DriverFields} from "./interfaces";
import axios from "../lib/axios";

export default async function DriverInfo(id: number): Promise<DriverFields> {
    let result = await axios.get(`/main/Station/${id}/`);
    return {
        id: result.data.id,
        name: result.data.full_name
    };
}

export async function getDriverList(): Promise<Array<DriverFields>> {
    let result = await axios.get('/main/Driver/', {
        params: {
            page_size: 1000
        }
    });
    let data = [];
    for (const k in result.data.results) {
        data.push({
            id: result.data.results[k].id,
            name: result.data.results[k].full_name
        });
    }
    return data;
}