import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from "universal-cookie";
import {useNavigate} from "react-router-dom";
import axios, {setAxiosDefaults} from 'lib/axios';
import {useDispatch} from "react-redux";
import {setToken} from "../../store/auth";

export default function LoginForm(props) {
    const [error, setError] = React.useState('');
    const [credentials, setCredentials] = React.useState({'username': null, 'password': null})
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmission = (e) => {
        console.log("Logging in...");
        const cookies = new Cookies();
        let custom_headers = {
            'Content-type': 'application/json'
        }
        if (custom_headers) {
            custom_headers['X-CSRFToken'] = cookies.get('csrftoken');
        }
        axios.post('/token-auth/', credentials, {
            headers: custom_headers,
            transformRequest: [
                (data, headers) => {
                    delete headers['Authorization'];
                    return JSON.stringify(data);
                },
            ],
        }).then(function (response) {
            if (response.data.hasOwnProperty('token')) {
                setAxiosDefaults({authToken: response.data['token']});
                dispatch(setToken(response.data['token']));
            } else {
                console.error(response.data);
                let _err = '';
                for (const key in response.data) {
                    _err += response.data[key];
                }
                setError(_err);
            }
        }).catch((error) => {
            console.debug(error);
            const response = error.response;
            let _err = '';
            for (const key in response.data) {
                _err += response.data[key];
            }
            setError(_err);
        });
    }

    return (
        <div>
            <Dialog open={true}>
                <DialogTitle>Авторизация</DialogTitle>
                <DialogContent>
                    <DialogContentText color={"red"}>
                        {error}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Логин"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setCredentials({...credentials, username: e.target.value})}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Пароль"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmission}>Войти</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
