import * as React from "react";
import {FormControl, MenuItem, TextField} from "@mui/material";
import {FuelType} from "../../lib/enums";

export default class FuelTypeFilter extends React.Component<{
    value: any,
    required?: boolean,
    asFilter: boolean,
    helperText?: string,
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}, {}> {
    constructor(props) {
        super(props);
    }

    getFuelList() {
        let list = [];
        if (this.props.asFilter)
            list.push(<MenuItem value={"__all__"} key="fuel_all">Все</MenuItem>);
        Object.values(FuelType).map((fuel) => {
            list.push(<MenuItem value={fuel[0]}
                                key={`fuel_${fuel[0]}`}>{fuel[1]}</MenuItem>);
        });
        return list
    }

    render() {
        return <>
            <FormControl fullWidth>
                <TextField
                    required={this.props?.required}
                    select
                    id="fuel-select"
                    label="Тип топлива"
                    value={this.props.value ? this.props.value : ''}
                    onChange={this.props.onChange}
                    helperText={this.props.helperText}
                >
                    {this.getFuelList()}
                </TextField>
            </FormControl>
        </>;
    }
}