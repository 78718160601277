import axios_original from 'axios';
import {getAPIUrl, loadFiltersData} from "./helper";
import {store} from "../store";
import {AxiosDefaults} from "../data_structures/AxiosStructures";
import {addWork, doneWork} from "../store/theme";

export const source = axios_original.CancelToken.source();

const axios = axios_original.create({
    baseURL: getAPIUrl()
});

export const setAxiosDefaults = (props: AxiosDefaults) => {
    axios.defaults.headers.common['Authorization'] = `Token ${props.authToken}`;
    store.dispatch(loadFiltersData);
}

axios.interceptors.request.use(
    config => {
        store.dispatch((dispatch, getState) => dispatch(addWork(null)));
        return config;
    }
)

axios.interceptors.response.use(function (config) {
    store.dispatch((dispatch, getState) => dispatch(doneWork(null)));
    return config;
}, function (error) {
    store.dispatch((dispatch, getState) => dispatch(doneWork(null)));
    if (error.response.status == 401) {
        source.cancel();
        localStorage.removeItem('at');
        window.location.reload();
    }
    return Promise.reject(error);
});

axios.defaults.cancelToken = source.token;
export default axios;