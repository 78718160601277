import {createSlice} from "@reduxjs/toolkit";
import StationState from "../data_structures/StationState";

const initialState: StationState = <StationState>{stations: {}, terminals: {}};

const stationsSlice = createSlice({
    name: 'stations',
    initialState,
    reducers: {
        setStations: (state, action) => {
            state.stations = action.payload;
        },
        setTerminals: (state, action) => {
            state.terminals = action.payload;
        },
    }
})

export const {setStations, setTerminals} = stationsSlice.actions;
export default stationsSlice.reducer;