export enum AjaxLoaderState {
    needsUpdate,
    inProcess,
    done
}

export const FuelType = {
    // gas: [0, 'Gas'],
    diesel: [1, 'Diesel'],
    ad_blue: [2, 'AdBlue'],
}