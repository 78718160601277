import TextField from "@mui/material/TextField";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {DriverFields} from "../../lib/interfaces";
import {useSelector} from "react-redux";
import {drivers} from "../../store/car";
import {AutocompleteChangeDetails, AutocompleteChangeReason} from "@mui/material";

export default function DriverAutocomplete(props) {
    const [open, setOpen] = React.useState(false);
    const drivers_list = useSelector(drivers);
    const options = Object.values(drivers_list);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
        props.onChange(value);
    }

    return <Autocomplete
        id="DriverAutoComplete"
        open={open}
        onOpen={(e) => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        fullWidth
        selectOnFocus
        clearOnBlur
        isOptionEqualToValue={(option: DriverFields, value: DriverFields) => option && value && option.id === value.id}
        getOptionLabel={(option: DriverFields) => option && option.name ? option.name : ''}
        options={options}
        value={props.value}
        onChange={handleChange}
        renderInput={(params) => (
            <TextField
                required={props?.required}
                {...params}
                label="Водитель"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )}
    />;

}