import * as React from 'react';
import {useRef} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "lib/axios";
import {CarFields} from "../../lib/interfaces";
import {AutocompleteChangeDetails, AutocompleteChangeReason} from "@mui/material";
import Car from "../../data_structures/Car";


export default function CarAutocomplete(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<readonly CarFields[]>([]);
    const [query, setQuery] = React.useState<string>('');
    const loading = open && options.length === 0;
    const previousController = useRef(null);

    const handleKeyUp = (event, value, reason) => {
        if (reason == 'reset') return;
        if (previousController.current) {
            previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        setQuery(value);
        setOptions([]);

        (async () => {
            let response = await axios.get('/main/Car/', {
                params: {
                    ...(query ? {search: query} : {})
                },
                signal: signal
            })
            if (response)
                setOptions([...response.data.results]);
        })().catch((e) => {
        });
    }

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleChange = (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
        props.onChange(value);
    }

    return (
        <Autocomplete
            id="CarAutoComplete"
            open={open}
            onOpen={(e) => {
                setOpen(true);
                handleKeyUp(e, '', null)
            }}
            onClose={() => {
                setOpen(false);
            }}
            fullWidth
            selectOnFocus
            clearOnBlur
            isOptionEqualToValue={(option: CarFields, value) => option && value && option.id === value.id}
            getOptionLabel={(option: Car) => option && option.number ? option.number : ''}
            options={options}
            loading={loading}
            onInputChange={handleKeyUp}
            value={props.value}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Машина"
                    required={props?.required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
