import * as React from "react";
import Grid from "@mui/material/Grid";
import BasicDateRangePicker from "./BasicDateRangePicker";
import {CarStatsFilterInterface, DateRange} from "../lib/interfaces";
import {DataGrid, GridValueFormatterParams} from "@mui/x-data-grid";
import {CustomFooterTotalComponent} from "./CustomFooterTotalComponent";
import axios from "../lib/axios";
import {default as dayjs} from 'dayjs';
import StationFilter from "./filters/StationFilter";
import {getTerminalInfoByStationAndFuel, loadFiltersData} from "../lib/helper";
import FuelTypeFilter from "./filters/FuelTypeFilter";
import CarAutocomplete from "./filters/CarAutocomplete";
import {connect} from "react-redux";

class FuelStats extends React.Component<{
    reduxState: any,
    dispatch: any
}, {
    header: Array<any>,
    rows: Array<any>,
    total_vol: number,
    stationCurrent: number | null,
    filters: CarStatsFilterInterface
}> {
    data: object;
    dateFormat: string = 'YYYY-MM-DD';

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            filters: {
                date: {from: dayjs(), to: dayjs()},
                station: '__all__',
                car: null,
                fuel_type: '__all__'
            },
            total_vol: 0,
            stationCurrent: null,
            header: [
                {
                    field: 'date__date',
                    headerName: 'Дата'
                },
                {
                    field: 'vol',
                    headerName: 'Объём',
                    type: 'number',
                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                        if (params.value == null) {
                            return '';
                        }

                        const valueFormatted = Number(params.value).toLocaleString();
                        return `${valueFormatted} Л`;
                    },
                },
                {
                    field: 'refilled',
                    headerName: 'Заправлено',
                    type: 'number',
                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                        if (params.value == null) {
                            return '';
                        }

                        const valueFormatted = Number(params.value).toLocaleString();
                        return `${valueFormatted} раз(а)`;
                    },
                },
            ]
        };
    }

    componentDidMount() {
        this.props.dispatch(loadFiltersData);
        this.setData();
    }

    handleFilters(_map: object) {
        this.setState({
            ...this.state,
            rows: [],
            filters: {
                ...this.state.filters,
                ..._map
            }
        }, () => this.setData());
    }

    setData() {
        let params = {};
        if (this.state.filters.date.from && this.state.filters.date.to) {
            params['timeframe_after'] = this.state.filters.date.from.format(this.dateFormat);
            params['timeframe_before'] = this.state.filters.date.to.format(this.dateFormat);
        }
        if (this.state.filters.station != '__all__') {
            params['station'] = this.state.filters.station;
        }
        if (this.state.filters.fuel_type != '__all__') {
            params['fuel_type'] = this.state.filters.fuel_type;
        }
        if (this.state.filters.car !== null) {
            params['car'] = this.state.filters.car.id;
        }
        axios.get('/refills/CarRefillsStats/',
            {
                params: params
            }
        ).then((response) => {
            let rows = [];
            let i = 0;
            let total_vol = 0;
            for (let [key, value] of Object.entries(response.data['results'])) {
                for (const [k, val] of Object.entries(value)) {
                    if (val == null) {
                        value[k] = 0;
                    }
                }
                value['id'] = i;
                total_vol += Number(value['vol']);
                rows.push(value);
                i++;
            }
            let state = {
                'rows': rows,
                'total_vol': total_vol,
            };
            if (this.state.filters.station != '__all__' && this.state.filters.fuel_type != '__all__') {
                const terminal = getTerminalInfoByStationAndFuel(
                    Object.values(this.props.reduxState.stations.terminals),
                    this.state.filters.station,
                    this.state.filters.fuel_type
                );
                state['stationCurrent'] = Number(terminal ? terminal.current : 0);
            } else {
                state['stationCurrent'] = null;
            }
            this.setState({
                ...this.state,
                ...state
            });
        });
    }

    render() {
        return <>
            <Grid item xs={12}>
                <h2>Статистика Заправок</h2>
            </Grid>
            <Grid item xs={12}>
                <BasicDateRangePicker range={this.state.filters.date}
                                      setter={(_range: DateRange) => (this.handleFilters({date: _range}))}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <StationFilter
                    asFilter={true}
                    value={this.state.filters.station}
                    onChange={(e) => (this.handleFilters({station: e.target.value}))}/>
            </Grid>
            <Grid item xs={12} md={4}>
                <FuelTypeFilter
                    asFilter={true}
                    value={this.state.filters.fuel_type}
                    onChange={(e) => (this.handleFilters({fuel_type: e.target.value}))}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <CarAutocomplete
                    asFilter={true}
                    value={this.state.filters.car}
                    onChange={(value) => (this.handleFilters({car: value}))}
                />
            </Grid>
            <Grid item xs={12} md={8} lg={12}>
                <div style={{height: 400, width: '100%'}}>
                    <div style={{display: 'flex', height: '100%'}}>
                        <div style={{flexGrow: 1}}>
                            <DataGrid columns={this.state.header} rows={this.state.rows}
                                      pageSize={10}
                                      rowsPerPageOptions={[10]}
                                      components={{
                                          Footer: CustomFooterTotalComponent,
                                      }}
                                      componentsProps={{
                                          footer: {
                                              total: this.state.total_vol,
                                              stationCurrent: this.state.stationCurrent
                                          },
                                      }}/>
                        </div>
                    </div>
                </div>
            </Grid></>;
    }
}

const setStoreToProps = (state) => ({'reduxState': state});

export default connect(setStoreToProps)(FuelStats);