import * as React from 'react';
import {createTheme, Theme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {ruRU} from "@mui/x-data-grid";
import {Alert, Snackbar, TextField} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import Button from "@mui/material/Button";
import axios from "../lib/axios";
import {CarFields, RecordInterface, StationFields} from "../lib/interfaces";
import stationInfo from "../lib/StationInfo";
import {loadFiltersData} from "../lib/helper";
import StationFilter from "../components/filters/StationFilter";
import Station from "../data_structures/Station";
import FuelTypeFilter from "../components/filters/FuelTypeFilter";
import CarAutocomplete from "../components/filters/CarAutocomplete";
import BaseComponent from "../components/BaseComponent";
import DriverAutocomplete from "../components/filters/DriverAutocomplete";
import {connect} from "react-redux";
import CarInfo from "../lib/CarInfo";
import ErrorDialog from "../components/ErrorDialog";
import {RecordFields} from "../data_structures/Fields";

class Record extends React.Component<{
    reduxStore: any,
    dispatch: any
}, {
    rows: Array<any>,
    header: Array<any>,
    errorDialog: boolean,
    date: Dayjs,
    formData: RecordInterface,
    formDataErrors: RecordInterface,
    stationForm: StationFields,
    currentStationFuelVolume: string,
    openSuccessBar: boolean,
    openErrorBar: boolean,
    gasStations: Array<Station>,
    cars: Array<CarFields>
}> {
    mdTheme: Theme;
    data: object;
    errors: Array<any>;

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            header: [],
            errorDialog: false,
            openSuccessBar: false,
            openErrorBar: false,
            date: dayjs(),
            formData: {
                before_refill: '',
                volume: '',
                after_refill: '',
                gps_current: '',
                odometer_current: '',
                started_at: dayjs(),
                car: null,
                station: '',
                driver: null,
                fuel_type: '',
                ride_type: 0
            },
            formDataErrors: {
                before_refill: '',
                volume: '',
                after_refill: '',
                gps_current: '',
                odometer_current: '',
                started_at: '',
                car: null,
                station: '',
                driver: null,
                fuel_type: '',
                ride_type: ''
            },
            stationForm: {
                station: '',
                terminal: '',
                fuel_type: null,
                volume: '',
                date: dayjs(),
                current: '',
                name: '',
                lastRefillIndex: ''
            },
            currentStationFuelVolume: null,
            gasStations: [],
            cars: [],
        };
        this.mdTheme = createTheme({}, ruRU);
        this.errors = [];
    }

    handleErrors(AxiosErrorsObject) {
        console.log(AxiosErrorsObject);
        this.errors = [];
        let dialog = false;
        if (AxiosErrorsObject.response) {
            if (AxiosErrorsObject.response.headers.getContentType().toLowerCase().search('application/json') !== -1) {
                const data = typeof AxiosErrorsObject.response.data == 'object' ? AxiosErrorsObject.response.data : JSON.parse(AxiosErrorsObject.response.data);
                for (const key in data) {
                    this.errors.push(`${RecordFields[key]} - ${AxiosErrorsObject.response.data[key]}`);
                }
            } else {
                this.errors.push(AxiosErrorsObject.response.data.toString());
                dialog = true;
            }
        }
        this.setState({...this.state, openErrorBar: !dialog, openSuccessBar: false, errorDialog: dialog});
    }

    handleStationRefillSubmit() {
        const data_to_submit = {
            ...this.state.stationForm,
            date: this.state.stationForm.date.format('YYYY-MM-DD')
        };
        axios.post(
            '/refills/StationRefills/',
            data_to_submit
        ).then((response) => {
            if (response.status != 201) {
                this.errors = response.data;
                this.setState({...this.state, openErrorBar: true, openSuccessBar: false});
            } else {
                this.errors = [];
                this.setState({
                    ...this.state,
                    currentStationFuelVolume: null,
                    openErrorBar: false,
                    openSuccessBar: true,
                    stationForm: {
                        station: '',
                        terminal: '',
                        fuel_type: null,
                        volume: '',
                        date: dayjs(),
                        current: '',
                        name: '',
                        lastRefillIndex: '',
                    }
                });
            }
        }).catch((error) => {
            this.handleErrors(error);
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        let data = {
            before_refill: this.state.formData.before_refill,
            volume: this.state.formData.volume,
            fuel_type: this.state.formData.fuel_type,
            after_refill: this.state.formData.after_refill,
            gps_current: this.state.formData.gps_current,
            odometer_current: this.state.formData.odometer_current,
            started_at: this.state.formData.started_at,
            car: this.state.formData.car ? this.state.formData.car.id : null,
            station: this.state.formData.station,
            driver: this.state.formData.driver.id,
            ride_type: this.state.formData.ride_type,
        };

        axios.post(
            '/main/CarOutForRide/',
            data
        ).then((response) => {
            if (response.data.hasOwnProperty('errors') && response.data.errors.length > 0) {
                this.errors = response.data.errors;
                this.setState({...this.state, openErrorBar: true});
            } else {
                this.errors = [];
                this.setState({
                    ...this.state, openErrorBar: false, openSuccessBar: true, formData: {
                        before_refill: '',
                        volume: '',
                        after_refill: '',
                        gps_current: '',
                        odometer_current: '',
                        started_at: dayjs(),
                        car: null,
                        station: '',
                        driver: null,
                        fuel_type: '',
                        ride_type: 0
                    }, formDataErrors: {
                        before_refill: '',
                        volume: '',
                        after_refill: '',
                        gps_current: '',
                        odometer_current: '',
                        started_at: '',
                        car: null,
                        station: '',
                        driver: null,
                        fuel_type: '',
                        ride_type: 0
                    }
                });
            }
        }).catch((error) => {
            this.handleErrors(error);
        });
    }

    handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({...this.state, openErrorBar: false, openSuccessBar: false});
    };

    setFormFieldValue(map: object) {
        let state = {...this.state, formData: {...this.state.formData, ...map}};
        let station = null, fuel_type = null, _update = false;
        if (map.hasOwnProperty('station')) {
            station = map['station'];
            _update = true;
        } else if (state.formData.station != null) {
            station = state.formData.station;
        }
        if (map.hasOwnProperty('fuel_type')) {
            fuel_type = map['fuel_type'];
            _update = true;
        } else if (state.formData.fuel_type) {
            fuel_type = state.formData.fuel_type;
        }
        if (station && fuel_type && _update) {
            const station_info = stationInfo(station);
            station_info.then((response) => {
                for (let terminal of response.fuel_terminals) {
                    if (terminal.fuel_type == fuel_type) {
                        state.formData['before_refill'] = terminal.index;
                        break;
                    }
                }
                if (state.formData.volume != null) {
                    state.formData.after_refill = Number(state.formData.before_refill) + Number(state.formData.volume)
                }
                this.setState({...this.state, ...state})
            }).catch((e) => {
                this.handleErrors(e);
            });
        }
        if (map.hasOwnProperty('volume')) {
            if (state.formData.before_refill != null) {
                state.formData.after_refill = Number(state.formData.before_refill) + Number(state.formData.volume)
            }
        }
        this.setState(state);
        if (map.hasOwnProperty('car') && map['car']) {
            CarInfo(map['car'].id).then((car) => {
                this.setState({
                    ...this.state,
                    formData: {...this.state.formData, odometer_current: car.odometer_current}
                });
            }).catch((e) => {
                this.handleErrors(e);
            });
        }
    }

    setStationFormFieldValue(map: object) {
        let state = {stationForm: {...this.state.stationForm, ...map}};
        let station = null, fuel_type = null, _update = false;
        if (map.hasOwnProperty('station')) {
            station = map['station'];
            _update = true;
        } else if (state.stationForm.station != null) {
            station = state.stationForm.station;
        }
        if (map.hasOwnProperty('fuel_type')) {
            fuel_type = map['fuel_type'];
            _update = true;
        } else if (state.stationForm.fuel_type) {
            fuel_type = state.stationForm.fuel_type;
        }
        if (station && fuel_type && _update) {
            const station_info = stationInfo(station);
            station_info.then((response) => {
                for (let terminal of response.fuel_terminals) {
                    if (terminal.fuel_type == fuel_type) {
                        state.stationForm.terminal = terminal.id;
                        state['currentStationFuelVolume'] = terminal.current;
                        break;
                    }
                }
                this.setState({...this.state, ...state})
            }).catch((e) => {
                this.handleErrors(e);
            });
        } else {
            this.setState({...this.state, ...state})
        }
    }

    checkVolumeAndTerminalIndexAreValid() {
        if (
            (this.state.formData.volume == null || this.state.formData.volume == '')
            &&
            (this.state.formData.gps_current == null || this.state.formData.gps_current == '')
        ) return true; // no check is needed

        const volume = Number(this.state.formData.volume), gps_current = Number(this.state.formData.gps_current);
        const validDiffPct = 1;  // Valid difference in %
        const a_bit_vol = volume * validDiffPct / 100;
        return !((gps_current < (volume - a_bit_vol)) || (gps_current > (volume + a_bit_vol)));
    }

    componentDidMount() {
        this.props.dispatch(loadFiltersData);
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        if (!this.checkVolumeAndTerminalIndexAreValid()) {
            this.state.formDataErrors.gps_current = 'Обьём топлива от GPS отличаеться от заправленного!';
        } else {
            this.state.formDataErrors.gps_current = null;
        }

        return (
            <BaseComponent>
                {/* Refill Car section */}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={12} mb={4}>
                        <div style={{minHeight: 400, width: '100%'}}>
                            <h2>Добавить запись заправки:</h2>
                            <form onSubmit={(e) => this.handleSubmit(e)}>
                                <React.Fragment>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={3}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                                localeText={{start: 'Check-in', end: 'Check-out'}}
                                            >
                                                <DateTimePicker
                                                    label="Дата"
                                                    value={this.state.formData.started_at}
                                                    onChange={(value: Dayjs | null) => (this.setFormFieldValue({started_at: value}))}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <StationFilter
                                                required
                                                asFilter={false}
                                                value={this.state.formData.station}
                                                onChange={(e) => (this.setFormFieldValue({station: e.target.value}))}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5}>
                                            <FuelTypeFilter
                                                required
                                                value={this.state.formData.fuel_type}
                                                onChange={(e) => (this.setFormFieldValue({fuel_type: e.target.value}))}
                                                asFilter={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <CarAutocomplete
                                                required
                                                asFilter={false}
                                                value={this.state.formData.car}
                                                onChange={(value) => (this.setFormFieldValue({car: value}))}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <DriverAutocomplete
                                                required
                                                asFilter={false}
                                                onChange={(value) => (this.setFormFieldValue({driver: value}))}
                                                value={this.state.formData.driver}/>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                required
                                                id="before_refill"
                                                name="before_refill"
                                                label="Начальный"
                                                value={this.state.formData.before_refill}
                                                fullWidth
                                                variant="standard"
                                                onChange={(e) => (this.setFormFieldValue({before_refill: e.target.value}))}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                required
                                                id="volume"
                                                name="volume"
                                                label="Топливо"
                                                value={this.state.formData.volume}
                                                fullWidth
                                                variant="standard"
                                                onChange={(e) => (this.setFormFieldValue({volume: e.target.value}))}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                required
                                                id="after_refill"
                                                name="after_refill"
                                                value={this.state.formData.after_refill}
                                                label="Конечный"
                                                fullWidth
                                                variant="standard"
                                                onChange={(e) => (this.setFormFieldValue({after_refill: e.target.value}))}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                required
                                                id="gps_current"
                                                name="gps_current"
                                                label="Оператор GPS"
                                                value={this.state.formData.gps_current}
                                                fullWidth
                                                autoComplete=""
                                                variant="standard"
                                                onChange={(e) => (this.setFormFieldValue({gps_current: e.target.value}))}
                                                error={this.state.formDataErrors.gps_current != null}
                                                helperText={this.state.formDataErrors.gps_current}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="odometer_current"
                                                name="odometer_current"
                                                label="Одометр (Км)"
                                                value={this.state.formData.odometer_current}
                                                fullWidth
                                                variant="standard"
                                                required
                                                onChange={(e) => (this.setFormFieldValue({odometer_current: e.target.value}))}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <React.Fragment>
                                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        sx={{mt: 3, ml: 1}}
                                                    >
                                                        Заправить
                                                    </Button>
                                                </Box>
                                            </React.Fragment>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            </form>
                        </div>
                    </Grid>
                </Grid>

                <Divider variant="fullWidth"/>
                {/*    Refill Station section */}
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8} lg={12}>
                        <div style={{height: 400, width: '100%'}}>
                            <h2>Статус заправок:</h2>
                            <React.Fragment>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={3}>
                                        <StationFilter
                                            asFilter={false}
                                            value={this.state.stationForm.station}
                                            onChange={(e) => (this.setStationFormFieldValue({station: e.target.value}))}
                                            helperText={this.state.currentStationFuelVolume == null ? null : this.state.currentStationFuelVolume + ' Л'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FuelTypeFilter
                                            value={this.state.stationForm.fuel_type}
                                            asFilter={false}
                                            onChange={(e) => (this.setStationFormFieldValue({fuel_type: e.target.value}))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            required
                                            id="station_refill_vol"
                                            name="station_refill_vol"
                                            label="Объём заправки станции"
                                            value={this.state.stationForm.volume}
                                            fullWidth
                                            variant="standard"
                                            onChange={(e) => (this.setStationFormFieldValue({volume: e.target.value}))}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            localeText={{start: 'Check-in', end: 'Check-out'}}
                                        >
                                            <DatePicker
                                                label="Дата заправки"
                                                value={this.state.stationForm.date}
                                                onChange={(value: Dayjs | null) => (this.setStationFormFieldValue({date: value}))}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Button
                                            id="station_refill_btn"
                                            variant="contained"
                                            onClick={() => (this.handleStationRefillSubmit())}
                                            sx={{mt: 1, ml: 2}}
                                            color="warning"
                                        >
                                            Заправить
                                        </Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        </div>
                    </Grid>
                </Grid>
                <Snackbar open={this.state.openSuccessBar} onClose={(i, k) => (this.handleClose(i, k))}>
                    <Alert onClose={(i) => (this.handleClose(i))} severity="success" sx={{width: '100%'}}>
                        Запись успешно добавлена!
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openErrorBar} onClose={(i, k) => (this.handleClose(i, k))}>
                    <Alert onClose={(i) => (this.handleClose(i))} severity="error" sx={{width: '100%'}}>
                        Ошибка при добавлении!
                        {this.errors.map((el) => (<p>{el}</p>))}
                    </Alert>
                </Snackbar>
                ({this.state.errorDialog ? <ErrorDialog msg={this.errors[0]} handleClose={() => this.setState({
                ...this.state,
                errorDialog: false
            })}/> : <></>})
            </BaseComponent>
        );
    }
}

const setStoreToProps = (store) => ({reduxStore: store});

export default connect(setStoreToProps)(Record);