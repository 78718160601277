import {createSlice} from "@reduxjs/toolkit";
import ThemeState from "../data_structures/ThemeState";

const initialState: ThemeState = <ThemeState>{
    menu: true,
    pageLoader: false,
    workCounter: 0
};

const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleMenu: (state, action) => {
            state.menu = !state.menu;
        },
        showLoader: (state, action) => {
            state.pageLoader = true;
        },
        hideLoader: (state, action) => {
            state.pageLoader = false;
        },
        addWork: (state, action) => {
            state.workCounter += 1;
            state.pageLoader = true;
        },
        doneWork: (state, action) => {
            state.workCounter -= 1;
            if (state.workCounter == 0) {
                state.pageLoader = false;
            }
        }
    }
});

export const {
    toggleMenu,
    showLoader,
    hideLoader,
    addWork,
    doneWork,
} = themeSlice.actions;
export default themeSlice.reducer;