import axios from "../lib/axios";
import Station from "../data_structures/Station";

export const controller = new AbortController();


export default async function stationInfo(id: number): Promise<Station> {
    let result = await axios.get(`/main/Station/${id}/`, {
        signal: controller.signal
    });

    return result.data;
}

export async function getStationsList(): Promise<Array<Station>> {
    let result = await axios.get('/main/Station/', {
        signal: controller.signal
    });

    return result.data.results;
}
