import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import {useDispatch} from "react-redux";
import {openLogoutDialog} from "../../store/auth";

export default function (props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useDispatch();

    return <>
        <Tooltip title="Профиль">
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ml: 2}}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Avatar sx={{width: 32, height: 32}}>A</Avatar>
            </IconButton>
        </Tooltip>
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
            {/*<MenuItem onClick={handleClose}>*/}
            {/*    <Avatar/> Profile*/}
            {/*</MenuItem>*/}
            {/*<MenuItem onClick={handleClose}>*/}
            {/*    <Avatar/> My account*/}
            {/*</MenuItem>*/}
            {/*<Divider/>*/}
            {/*<MenuItem onClick={handleClose}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <PersonAdd fontSize="small"/>*/}
            {/*    </ListItemIcon>*/}
            {/*    Add another account*/}
            {/*</MenuItem>*/}
            {/*<MenuItem onClick={handleClose}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <Settings fontSize="small"/>*/}
            {/*    </ListItemIcon>*/}
            {/*    Settings*/}
            {/*</MenuItem>*/}
            <MenuItem onClick={() => {
                dispatch(openLogoutDialog(true));
                handleClose();
            }}>
                <ListItemIcon>
                    <Logout fontSize="small"/>
                </ListItemIcon>
                Выйти
            </MenuItem>
        </Menu>
    </>;
}